<template>
    <div class="p-3">
        <el-card shadow="never">
            <div class="row no-gutters">
                <div class="col">
                    <search-box v-model="key" style="width: 280px;" @search="loadPlantContracts(true)" />
                </div>
            </div>
            <el-table :data="plantContracts.data" class="mt-2">
                <el-table-column prop="year" label="年份" sortable width="80" />
                <el-table-column label="名称/编号">
                    <template #default="scope">
                        <div>
                            <router-link :to="'/plant-contract/detail?id='+scope.row.id" target="_blank">
                                {{scope.row.name}}
                            </router-link>
                        </div>
                        <div class="text-info">{{scope.row.code}}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="farmerName" label="种植户" sortable width="160" />
                <el-table-column prop="warrantorNames" label="担保人" sortable width="160" />
                <el-table-column prop="totalArea" label="总面积（亩）" sortable width="160" />
                <el-table-column label="承包日期" width="140">
                    <template #default="scope">
                        <div>{{$moment.ld(scope.row.beginContractDate)}}</div>
                        <div>{{$moment.ld(scope.row.endContractDate)}}</div>
                    </template>
                </el-table-column>
                <el-table-column label="操作" fixed="right" width="240">
                    <template #default="scope">
                        <el-button type="text" @click="showPlantContractOnlinePageDialog(scope.row)">查看合同</el-button>
                        <el-button type="text" @click="syncPlantContractOnline(scope.row.id)">同步状态</el-button>
                        <el-button type="text" @click="showSignPlantContractOfflineDialog(scope.row)">转线下签约</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination layout="total,->,prev,pager,next" :total="plantContracts.totalRecords"
                :current-page="plantContractsPagination.currentPage" :page-size="plantContractsPagination.pageSize"
                class="mt-3" @current-change="plantContractsPaginationCurrentChange" />
        </el-card>

        <el-dialog :visible.sync="plantContractOnlinePageDialogVisible" title="合同" :close-on-click-modal="false"
            width="960px" top="0px">
            <iframe v-if="plantContractOnlinePageDialogVisible&&contractPageUrl" :src="contractPageUrl" frameborder="0"
                class="d-block border border-primary rounded w-100" style="height: calc(100vh - 110px);"></iframe>
        </el-dialog>

        <el-dialog :visible.sync="signPlantContractOfflineDialogVisible" title="线上转线下签约" :close-on-click-modal="false"
            width="640px">
            <el-form>
                <el-form-item label="线上转线下原因" class="form-item-required">
                    <el-input v-model="reason" placeholder="线上转线下原因" />
                </el-form-item>
            </el-form>
            <div>
                <div>线下签约合同附件</div>
                <div class="border rounded mt-2 p-1">
                    <multi-file-uploader v-model="plantContract.signOfflineContractAttachments"
                        accept=".pdf,.jpg,.jpeg,.png" />
                </div>
                <div class="text-info mt-2">上传已线下签章生效的PDF、图片附件。</div>
            </div>
            <template #footer>
                <el-button type="primary" @click="signPlantContractOffline">确定</el-button>
                <el-button @click="signPlantContractOfflineDialogVisible=false">取消</el-button>
            </template>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                key: null,
                plantContracts: {},
                plantContractsPagination: {
                    currentPage: 1,
                    pageSize: 10,
                },
                plantContract: {},
                contractPageUrl: null,
                reason: null,
                plantContractOnlinePageDialogVisible: false,
                signPlantContractOfflineDialogVisible: false,
            };
        },
        methods: {
            async loadPlantContracts(resetPage = false) {
                if (resetPage) {
                    this.plantContractsPagination.currentPage = 1;
                }

                let response = await this.$axios.get('/api/PlantContract/GetPlantContracts', {
                    params: {
                        companyId: this.$store.state.companyId,
                        status: 4,
                        key: this.key,
                        pageIndex: this.plantContractsPagination.currentPage - 1,
                        pageSize: this.plantContractsPagination.pageSize,
                    }
                });
                this.plantContracts = response.data;
            },
            plantContractsPaginationCurrentChange(page) {
                this.plantContractsPagination.currentPage = page;
                this.loadPlantContracts();
            },
            showPlantContractOnlinePageDialog(plantContract) {
                this.plantContract = JSON.parse(JSON.stringify(plantContract));
                this.contractPageUrl = null;
                this.plantContractOnlinePageDialogVisible = true;

                this.loadContractPageUrl();
            },
            async loadContractPageUrl() {
                let response = await this.$axios.get('/api/Contract/GetContractPageUrl', {
                    params: { contractId: this.plantContract.signOnlineContractId }
                });
                this.contractPageUrl = response.data;
            },
            showSignPlantContractOfflineDialog(plantContract) {
                this.plantContract = JSON.parse(JSON.stringify(plantContract));
                this.reason = null;
                this.signPlantContractOfflineDialogVisible = true;
            },
            async signPlantContractOffline() {
                if (!confirm('确定要签约吗？')) {
                    return;
                }

                await this.$axios.post('/api/PlantContract/SignPlantContractToOffline', {
                    id: this.plantContract.id,
                    reason: this.reason,
                    signOfflineContractAttachments: this.plantContract.signOfflineContractAttachments,
                });
                this.signPlantContractOfflineDialogVisible = false;
                this.loadPlantContracts();
            },
            async syncPlantContractOnline(id) {
                await this.$axios.post('/api/PlantContract/SyncPlantContractOnline', { id: id });
                this.$message.success('同步完成。');
                this.loadPlantContracts();
            },
        },
        created() {
            this.loadPlantContracts();
        },
    };
</script>